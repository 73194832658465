import Vue from "vue";
import Router from "vue-router";
import Home from "./views/Home.vue";

const HACKS = [
    // Hack links:
    {
        path: "/microns/minnie",
        props: { projectId: "microns-minnie" },
        meta: {
            title: "IARPA MICrONS Minnie"
        },
        component: () =>
            import(/* webpackChunkName: "project" */ "./views/Project.vue")
    },
    {
        path: "/microns/pinky",
        props: { projectId: "microns-pinky" },
        meta: {
            title: "IARPA MICrONS Pinky"
        },
        component: () =>
            import(/* webpackChunkName: "project" */ "./views/Project.vue")
    },
    {
        path: "/microns/basil",
        props: { projectId: "microns-basil" },
        meta: {
            title: "IARPA MICrONS Basil"
        },
        component: () =>
            import(/* webpackChunkName: "project" */ "./views/Project.vue")
    },
    {
        path: "/microns/interneuron",
        props: { projectId: "microns-interneuron" },
        meta: {
            title: "IARPA MICrONS Interneuron"
        },
        component: () =>
            import(/* webpackChunkName: "project" */ "./views/Project.vue")
    },
    {
        path: "/prasad2020",
        props: { projectId: "prasad2020" },
        meta: {
            title: "Prasad 2020"
        },
        component: () =>
            import(/* webpackChunkName: "project" */ "./views/Project.vue")
    },
    {
        path: "/project/maniatesselvin_hildebrand_graham2020",
        props: { projectId: "phelps_hildebrand_graham2020" },
        meta: {
            title: "Phelps, Hildebrand, & Graham 2020"
        },
        component: () =>
            import(/* webpackChunkName: "project" */ "./views/Project.vue")
    },
    {
        path: "/project/allenInstitute2017",
        props: { projectId: "allenMouseReferenceAtlas2017" },
        meta: {
            title: "Phelps, Hildebrand, & Graham 2020"
        },
        component: () =>
            import(/* webpackChunkName: "project" */ "./views/Project.vue")
    },
    {
        path: "/project/pauker2024",
        props: { projectId: "paukner2024" },
        meta: {
            title: "Paukner 2024"
        },
        component: () =>
            import(/* webpackChunkName: "project" */ "./views/Project.vue")
    },
    {
        path: "/project/plazaalonso2024",
        props: { projectId: "plaza-alonso2024" },
        meta: {
            title: "Plaza-Alonso 2024"
        },
        component: () =>
            import(/* webpackChunkName: "project" */ "./views/Project.vue")
    },
    {
        path: "/project/Kharris15",
        props: { projectId: "harris2015" },
        meta: {
            title: "Harris et al. 2015"
        },
        component: () =>
            import(/* webpackChunkName: "project" */ "./views/Project.vue")
    },
    {
        path: "/project/microns-basil",
        props: { projectId: "microns_basil2019" },
        meta: {
            title: "IARPA MICrONS Basil"
        },
        component: () =>
            import(/* webpackChunkName: "project" */ "./views/Project.vue")
    },
    {
        path: "/project/microns-minnie",
        props: { projectId: "microns_minnie2021" },
        meta: {
            title: "IARPA MICrONS Minnie"
        },
        component: () =>
            import(/* webpackChunkName: "project" */ "./views/Project.vue")
    },
    {
        path: "/project/microns-pinky",
        props: { projectId: "microns_pinky2021" },
        meta: {
            title: "IARPA MICrONS Pinky100"
        },
        component: () =>
            import(/* webpackChunkName: "project" */ "./views/Project.vue")
    },
    {
        path: "/project/allenMouseReferenceAtlas2017",
        props: { projectId: "allen2017" },
        meta: {
            title: "Allen Mouse Reference Atlas"
        },
        component: () =>
            import(/* webpackChunkName: "project" */ "./views/Project.vue")
    },
    {
        path: "/project/kristina2015",
        props: { projectId: "micheva2015" },
        meta: {
            title: "Micheva et al. 2015"
        },
        component: () =>
            import(/* webpackChunkName: "project" */ "./views/Project.vue")
    },
    {
        path: "/project/helmstaedter13",
        props: { projectId: "helmstaedter2013" },
        meta: {
            title: "Helmstaedter et al. 2013"
        },
        component: () =>
            import(/* webpackChunkName: "project" */ "./views/Project.vue")
    },
    {
        path: "/project/flywire",
        props: { projectId: "flywire2020" },
        meta: {
            title: "Flywire 2020"
        },
        component: () =>
            import(/* webpackChunkName: "project" */ "./views/Project.vue")
    }
];

const POSTS = [
    {
        path: "/post/syglass-bossdb",
        name: "post",
        props: { postId: "syglass-bossdb" },
        meta: {
            title: "SyGlass and BossDB"
        },
        component: () =>
            import(
                /* webpackChunkName: "post" */ "./components/posts/SyGlass.vue"
            )
    },
    {
        path: "/post/open-data",
        name: "post",
        props: { postId: "open-data" },
        meta: {
            title: "Sharing Open Data with BossDB"
        },
        component: () =>
            import(
                /* webpackChunkName: "post" */ "./components/posts/OpenData.vue"
            )
    },
    {
        path: "/post/bossdb-dois",
        name: "post",
        props: { postId: "bossdb-dois" },
        meta: {
            title: "Announcing BossDB dataset DOIs"
        },
        component: () =>
            import(/* webpackChunkName: "post" */ "./components/posts/DOIs.vue")
    }
];

Vue.use(Router);

let router = new Router({
    mode: "history",
    base: process.env.BASE_URL,
    routes: [
        ...HACKS,
        ...POSTS,

        {
            path: "/",
            name: "home",
            meta: {
                title: "Home"
            },
            component: Home
        },

        {
            path: "/privacy",
            name: "privacy",
            meta: {
                title: "Privacy Policy"
            },
            component: () =>
                import(
                    /* webpackChunkName: "privacy" */ "./views/PrivacyPolicy.vue"
                )
        },
        {
            path: "/tos",
            name: "terms",
            meta: {
                title: "Terms of Service"
            },
            component: () =>
                import(
                    /* webpackChunkName: "privacy" */ "./views/TermsOfService.vue"
                )
        },
        {
            path: "/projects",
            name: "projects",
            meta: {
                title: "Projects"
            },
            component: () =>
                import(
                    /* webpackChunkName: "projects" */ "./views/Projects.vue"
                )
        },

        {
            // DO NOT CHANGE THIS.
            // Collaborators rely upon these URLs staying the same.
            path: "/project/:projectId",
            name: "project",
            props: true,
            meta: {
                title: "Project"
            },
            component: () =>
                import(/* webpackChunkName: "project" */ "./views/Project.vue")
        },

        {
            path: "/tag/:tag",
            name: "tag",
            props: true,
            meta: {
                title: "Tagged"
            },
            component: () =>
                import(/* webpackChunkName: "tag" */ "./views/Tag.vue")
        },

        // TOOLS AND TOOL PAGES
        {
            path: "/tools",
            name: "tools",
            meta: {
                title: "Tools"
            },
            component: () =>
                import(/* webpackChunkName: "tools" */ "./views/Tools.vue")
        },
        {
            path: "/tools/:toolName",
            name: "tool",
            props: true,
            meta: {
                title: "Tools"
            },
            component: () =>
                import(/* webpackChunkName: "tool" */ "./views/Tool.vue")
        },

        {
            path: "/about",
            name: "about",
            meta: {
                title: "About"
            },
            component: () =>
                import(/* webpackChunkName: "about" */ "./views/About.vue")
        },
        {
            path: "/brain_initiative",
            name: "brainInitiative",
            meta: {
                title: "Brain Initiative"
            },
            component: () =>
                import(
                    /* webpackChunkName: "tools" */ "./views/BrainInitiative.vue"
                )
        },
        {
            path: "/publications",
            name: "publications",
            meta: {
                title: "Publications"
            },
            component: () =>
                import(
                    /* webpackChunkName: "publications" */ "./views/Publications.vue"
                )
        },
        {
            path: "/get-started",
            name: "getStarted",
            meta: {
                title: "Get Started"
            },
            component: () =>
                import(
                    /* webpackChunkName: "publications" */ "./components/GetStarted.vue"
                )
        },
        {
            path: "/data-ecosystem",
            name: "data-ecosystem",
            meta: {
                title: "BossDB Data Ecosystem"
            },
            component: () =>
                import(
                    /* webpackChunkName: "data-ecosystem" */ "./views/DataEcosystem.vue"
                )
        },
        {
            path: "/new-project",
            name: "newProject",
            meta: {
                title: "New Project"
            },
            component: () =>
                import(
                    /* webpackChunkName: "new-project" */ "./views/NewProject.vue"
                )
        },

        // A 404 page:
        {
            path: "*",
            name: "NotFound",
            meta: { title: "404" },
            component: () =>
                import(
                    /* webpackChunkName: "NotFound" */ "./views/NotFound.vue"
                )
        }
    ]
});

router.afterEach(next => {
    document.title = `BossDB.org | ${next.meta.title}`;
    return next;
});

export default router;
